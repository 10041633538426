const InitialState = {
  option: { value: { label: null, key: null }, label: null },
  duration: { value: { label: null, key: null }, label: null },
  additional_weeks: { value: { weeks: null, cost: null }, label: null },
  level: { value: { label: null, cost: null, key: null }, label: null },
  payment_type: { value: { label: null, discount: null }, label: null },
  payment_plan: [],
}

export default InitialState
