import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import { STRIPE } from "../../config"

const stripePromise = loadStripe(STRIPE.API_KEY)

const StripeWrapper = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
)

export default StripeWrapper
