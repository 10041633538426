import React from "react"
import { v4 as uuidv4 } from "uuid"

import Select from "@components/Select"
import { StaticPlacement } from "@components/ProgramLevels"
import { HR } from "@components/StyledElements"
import { FormatCurrenyOutput, CalculatePriceWithDiscount } from "@utils"
import {
  COACHING_OPTIONS,
  COACHING_PAYMENTS_TYPES,
  COACHING_PAYMENTS,
  COACHING_DURATION,
  COACHING_EXTRA_WEEKS,
  COACHING_LEVELS,
} from "@config"

const PaymentSelect = ({ state, dispatch, setIsReady }) => {
  //! -----------------------------------------------------------------------
  const renderCoachingOptions = () => {
    return (
      <div className="row">
        <div className="col-md-8 mx-auto d-flex flex-column align-items-center">
          <Select
            options={COACHING_OPTIONS}
            value={state.option}
            placeholder="Coaching Options"
            name="coaching_options"
            onSelect={val => {
              dispatch({
                type: "option",
                payload: val,
              })
              setIsReady(false)
            }}
          />
        </div>
        {state?.option?.label && (
          <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
            <h6>
              {state.option.label}
              <span role="img" aria-label="Flex">
                💪
              </span>
            </h6>
          </div>
        )}
        <div className="col-md-8 mx-auto mt-2">
          <HR />
        </div>
      </div>
    )
  }
  //! -----------------------------------------------------------------------
  const renderCoachingPaymentPlan = () => {
    if (
      state?.option &&
      state?.option?.value?.key === "one_off" &&
      state?.payment &&
      state?.payment?.label === "Payment Plan"
    ) {
      return (
        <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
          {COACHING_PAYMENTS.map(({ value }) => {
            const price = state.option.value.cost
            return (
              <h6 key={uuidv4()}>
                {value.label} -{" "}
                {FormatCurrenyOutput(
                  CalculatePriceWithDiscount(price, value.split)
                )}{" "}
                <span role="img" aria-label="Flex">
                  💪
                </span>
              </h6>
            )
          })}
        </div>
      )
    }
    if (state.option && state.option.value.key === "one_off" && state.payment) {
      const price = state.option.value.cost
      return (
        <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
          <h6>
            {FormatCurrenyOutput(price)}{" "}
            <span role="img" aria-label="Flex">
              💪
            </span>
          </h6>
        </div>
      )
    }
    return null
  }
  //! -----------------------------------------------------------------------
  const renderOneOffPaymentOptions = () => {
    if (state?.option && state?.option?.value?.key === "one_off") {
      return (
        <div className="row">
          <div className="col-md-8 mx-auto d-flex flex-column align-items-center">
            <Select
              options={COACHING_PAYMENTS_TYPES[state.option.value.key]}
              value={state.payment}
              placeholder="Payment Options"
              name="payment_options"
              onSelect={val => {
                dispatch({
                  type: "payment",
                  payload: val,
                })
                setIsReady(true)
              }}
            />
          </div>
          {renderCoachingPaymentPlan()}
          <div className="col-md-8 mx-auto mt-2">
            <HR />
          </div>
        </div>
      )
    }
    return null
  }
  //! -----------------------------------------------------------------------
  const renderDurationOptions = () => {
    if (state?.option?.value?.key && state?.option?.value?.key !== "one_off") {
      return (
        <div className="row">
          <div className="col-md-8 mx-auto d-flex flex-column align-items-center">
            <Select
              options={COACHING_DURATION[state.option.value.key]}
              value={state.duration}
              placeholder="Duration Options"
              name="coaching_duration"
              onSelect={val => {
                dispatch({
                  type: "duration",
                  payload: val,
                })
                setIsReady(false)
              }}
            />
          </div>
          {state?.duration?.label && (
            <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
              <h6>
                {state.duration.label}{" "}
                <span role="img" aria-label="Flex">
                  💪
                </span>
              </h6>
            </div>
          )}
          <div className="col-md-8 mx-auto mt-2">
            <HR />
          </div>
        </div>
      )
    }
    return null
  }
  //! -----------------------------------------------------------------------
  const renderLevelOptions = () => {
    if (state?.option?.value?.key && state?.duration?.value?.key) {
      return (
        <div className="row">
          <div className="col-md-8 mx-auto d-flex flex-column align-items-center">
            <Select
              options={
                COACHING_LEVELS[state.option.value.key][
                  state.duration.value.key
                ]
              }
              value={state.level}
              placeholder="Level Options"
              name="level_options"
              onSelect={val => {
                dispatch({ type: "level", payload: val })
                setIsReady(false)
              }}
            />
          </div>
          {state?.level?.label && (
            <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
              <h6>
                {state.level.label}{" "}
                <span role="img" aria-label="Flex">
                  💪
                </span>
              </h6>
            </div>
          )}
          <div className="col-md-8 mx-auto mt-2">
            <HR />
          </div>
        </div>
      )
    }
    return null
  }
  //! -----------------------------------------------------------------------
  const renderAdditionalWeeks = () => {
    if (
      state?.option?.value &&
      state?.option?.value?.key === "pre_contest" &&
      state?.duration?.value?.key &&
      state?.level?.value?.key
    ) {
      return (
        <div className="row">
          <div className="col-md-8 mx-auto d-flex flex-column align-items-center">
            <Select
              options={
                COACHING_EXTRA_WEEKS[state.option.value.key][
                  state.level.value.key
                ]
              }
              value={state.additional_weeks}
              placeholder="Add Additional Weeks?"
              name="additional_weeks"
              onSelect={val => {
                dispatch({
                  type: "additional_weeks",
                  payload: val,
                })
                setIsReady(false)
              }}
            />
          </div>
          {state?.additional_weeks?.label && (
            <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
              <h6>
                {state.additional_weeks.label}{" "}
                <span role="img" aria-label="Flex">
                  💪
                </span>
              </h6>
            </div>
          )}
          <div className="col-md-8 mx-auto mt-2">
            <HR />
          </div>
        </div>
      )
    }
    return null
  }
  //! -----------------------------------------------------------------------
  const renderPaymentPlanDetails = () => {
    if (state?.payment_type?.label === "Payment Plan") {
      return (
        <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
          {COACHING_PAYMENTS.map(payment => {
            const price = state?.level?.value?.cost
            const additionalWeeks = state?.additional_weeks?.value.cost
            return (
              <h6 key={uuidv4()}>
                {payment.label} -{" "}
                {FormatCurrenyOutput(
                  CalculatePriceWithDiscount(
                    price + additionalWeeks,
                    payment.value.split
                  )
                )}{" "}
                <span role="img" aria-label="Flex">
                  💪
                </span>
              </h6>
            )
          })}
        </div>
      )
    } else if (
      state?.payment_type?.label === "Full Payment Upfront (save 10%)" ||
      state?.payment_type?.label === "Full Payment"
    ) {
      const price = state?.level?.value?.cost
      const additionalWeeks = state?.additional_weeks?.value?.cost
      const discount = state?.payment_type?.value?.discount || 0
      return (
        <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
          <h6>
            {FormatCurrenyOutput(
              CalculatePriceWithDiscount(price + additionalWeeks, discount)
            )}{" "}
            <span role="img" aria-label="Flex">
              💪
            </span>
          </h6>
        </div>
      )
    }

    return null
  }
  //! -----------------------------------------------------------------------
  const renderPaymentOptions = () => {
    if (
      state?.option?.value?.key &&
      state?.duration?.value?.key &&
      state?.level?.value?.key
    ) {
      return (
        <div className="row">
          <div className="col-md-8 mx-auto d-flex flex-column align-items-center">
            <Select
              options={COACHING_PAYMENTS_TYPES[state.option.value.key]}
              value={state.payment}
              placeholder="Payment Options"
              name="payment_type"
              onSelect={val => {
                dispatch({
                  type: "payment_type",
                  payload: val,
                })
                setIsReady(true)
              }}
            />
          </div>
          {state?.payment_type?.label && (
            <div className="col-md-8 mx-auto d-flex flex-column align-items-center mt-2">
              <h6>
                {state.payment_type.label}{" "}
                <span role="img" aria-label="Flex">
                  💪
                </span>{" "}
              </h6>
            </div>
          )}
          {renderPaymentPlanDetails()}
          <div className="col-md-8 mx-auto mt-2">
            <HR />
          </div>
        </div>
      )
    }
    return null
  }
  //! -----------------------------------------------------------------------

  //! -----------------------------------------------------------------------
  return (
    <>
      <StaticPlacement className="col-md-8 mx-auto" />
      {/* ---------------------------------------------------------------- */}
      {/* STARTING POINT */}
      {renderCoachingOptions()}
      {/* ---------------------------------------------------------------- */}
      {/* ONE-OFF ONLY */}
      {renderOneOffPaymentOptions()}
      {/* ---------------------------------------------------------------- */}
      {/* OTHERS CONT'D */}
      {renderDurationOptions()}
      {/* ---------------------------------------------------------------- */}
      {renderLevelOptions()}
      {/* ---------------------------------------------------------------- */}
      {/* PRE-CONTEST ONLY */}
      {renderAdditionalWeeks()}
      {/* ---------------------------------------------------------------- */}
      {renderPaymentOptions()}
      {/* ---------------------------------------------------------------- */}
    </>
  )
}

export default PaymentSelect
