import React, { useState, useReducer } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import isEmail from "validator/lib/isEmail"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"
import Axios from "axios"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js"

import { Input, Button, Error, Success } from "@components/Form"
import Loader from "@components/Loader"
import PaymentSelect from "./PaymentSelect"
import "../Stripe/CardSection.css"
import { StripeLogo } from "@components/Stripe"

import { SERVER_URL } from "@config"
import InitialState from "./InitialState"
import Reducer from "./Reducer"

const createCardOptions = () => {
  return {
    style: {
      base: {
        backgroundColor: "#1b1b1b",
        color: "#d41f1f",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#8e8e8e",
        },
        ":-webkit-autofill": {
          backgroundColor: "#1b1b1b",
          color: "#d41f1f",
        },
      },
      invalid: {
        color: "#d41f1f",
        iconColor: "#d41f1f",
      },
    },
  }
}

const PaymentForm = ({ SetTempRegister }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  //! Form control via server validation
  const [isRegistered, setIsRegistered] = useState(false)
  const [registeredMessage, setRegisteredMessage] = useState("")
  const [registeredErrorMessage, setRegisteredErrorMessage] = useState("")
  //! Payment gate
  const [isReady, setIsReady] = useState(false)
  //! Payment logic via reducer
  const [state, dispatch] = useReducer(Reducer, InitialState)
  //! Stripe input state focus
  const [expRef, setExpRef] = useState("")
  const [cvcRef, setCvcRef] = useState("")
  //! Stripe error/success
  const [stripeError, setStripeError] = useState("")
  const [stripeSuccess, setStripeSuccess] = useState("")
  const stripe = useStripe()
  const elements = useElements()
  //! useForm
  const { register, handleSubmit, errors, getValues } = useForm()

  const onSubmit = async formData => {
    try {
      setIsDisabled(true)
      setIsLoading(true)
      if (!stripe || !elements) {
        setIsDisabled(false)
        setIsLoading(false)
        return
      }

      const { name, email } = formData

      const {
        option,
        duration,
        additional_weeks,
        level,
        payment_type,
        payment_plan,
      } = state

      const card = elements.getElement(CardNumberElement)
      const result = await stripe.createToken(card)
      if (result.error) {
        // Show error to your customer.
        console.error(result.error.message)
        setStripeError(result.error.message)
        setStripeSuccess("")
        setIsDisabled(false)
        setIsLoading(false)
        return
      }
      const token = result.token.id
      const { data } = await Axios.post(`${SERVER_URL}/payment`, {
        name,
        email,
        option,
        duration,
        additional_weeks,
        level,
        payment_type,
        payment_plan,
        token,
      })
      //! Set email and token in redux
      SetTempRegister({ email, token: data.token })
      setStripeSuccess(data.message)
      setStripeError("")
      //! Navigate to client login setup
      navigate(`/payment/success?email=${email}&token=${data.token}`)
      setIsDisabled(false)
      setIsLoading(false)
    } catch (submitError) {
      console.error(
        "submitError",
        submitError?.response?.data?.message || submitError
      )
      setStripeError(
        submitError?.response?.data?.message || "Unknown Error Occured"
      )
      setStripeSuccess("")
      setIsDisabled(false)
      setIsLoading(false)
    }
  }
  const handlePreCheck = async e => {
    try {
      e.preventDefault()
      const { email } = getValues()
      setRegisteredErrorMessage("")
      setRegisteredMessage("Checking For Valid Registration")
      const { data } = await Axios.post(`${SERVER_URL}/pre-payment-check`, {
        email,
      })
      setIsRegistered(true)
      setRegisteredMessage(data.message)
    } catch (error) {
      setIsRegistered(false)
      setRegisteredMessage("")
      setRegisteredErrorMessage(error.response.data.message)
    }
  }
  const handleOnChange = ({ complete, elementType, error }) => {
    if (error && error !== undefined) {
      setStripeError(error.message)
    } else {
      setStripeError("")
    }
    if (complete) {
      if (elementType === "cardNumber") {
        expRef.focus()
      } else if (elementType === "cardExpiry") {
        cvcRef.focus()
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 py-3 text-center">
          <h1>Payment Setup</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 mx-auto d-flex flex-column">
              <label className="text-center mt-1" htmlFor="email">
                Email
              </label>
              <Input
                width={"100%"}
                name="email"
                type="email"
                placeholder="Email"
                ref={register({
                  required: "Enter registration email",
                  validate: input => isEmail(input),
                })}
                required
              />
              {errors.email && <Error message={errors.email.message} />}
              {registeredErrorMessage && (
                <Error message={registeredErrorMessage} />
              )}
              {registeredMessage && <Success message={registeredMessage} />}
            </div>
            <div className="col-md-8 mx-auto text-center">
              <Button onClick={e => handlePreCheck(e)}>Validate Email</Button>
            </div>
          </div>
        </div>

        {isRegistered && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 mx-auto mt-4" />
            </div>

            {PaymentSelect({ state, dispatch, setIsReady })}

            {isReady && (
              <>
                <div className="row">
                  <div className="col-md-8 mx-auto d-flex flex-column">
                    <label className="text-center mt-1" htmlFor="name">
                      Name On Card
                    </label>
                    <Input
                      width={"100%"}
                      name="name"
                      type="text"
                      placeholder="Name On Card"
                      ref={register({
                        required: "Enter your full name",
                      })}
                      required
                    />
                    {errors.name && <Error message={errors.name.message} />}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <div className="row">
                      <div className="col-12 text-center">
                        <label
                          className="text-center mt-1"
                          htmlFor="cardNumber"
                        >
                          Card Number
                        </label>
                        <CardNumberElement
                          id="cardNumber"
                          name="cardNumber"
                          onChange={handleOnChange}
                          options={createCardOptions()}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-center">
                        <label className="mt-1" htmlFor="cardExpiry">
                          Exp
                        </label>
                        <CardExpiryElement
                          id="cardExpiry"
                          name="cardExpiry"
                          onChange={handleOnChange}
                          onReady={el => setExpRef(el)}
                          options={createCardOptions()}
                        />
                      </div>
                      <div className="col-6 text-center">
                        <label className="mt-1" htmlFor="cardCvc">
                          CVC
                        </label>
                        <CardCvcElement
                          id="cardCvc"
                          name="cardCvc"
                          onChange={handleOnChange}
                          onReady={el => setCvcRef(el)}
                          options={createCardOptions()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {!isLoading ? (
                    <div className="col-12 text-center">
                      <Button
                        disabled={isDisabled}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Complete Payment
                      </Button>
                    </div>
                  ) : (
                    <div className="col-12 mt-2 text-center">
                      <Loader message={"Finalizing Payment"} />
                    </div>
                  )}
                  {stripeError && (
                    <div className="col-12 mt-4 text-center">
                      <Error message={stripeError} />
                    </div>
                  )}
                  {stripeSuccess && (
                    <div className="col-12 mt-4 text-center">
                      <Success message={stripeSuccess} />
                    </div>
                  )}
                </div>

                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <StripeLogo />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </form>
    </>
  )
}

const MapStateToProps = state => {
  return {
    loading: state.auth.loading,
  }
}

export default connect(MapStateToProps, actions)(PaymentForm)
