import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { StripeWrapper } from "@components/Stripe"
import PaymentForm from "@components/PaymentForm"

const PaymentPage = ({ data }) => {
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Complete Payment" />
        <StripeWrapper>
          <PaymentForm />
        </StripeWrapper>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PaymentQuery {
    banner: allFile(filter: { name: { eq: "payment_setup_1440x500" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default PaymentPage
