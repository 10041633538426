const Reducer = (state, action) => {
  const { type } = action
  switch (type) {
    case "option":
      return {
        ...state,
        option: action.payload,
        duration: { value: { label: null, key: null }, label: null },
        level: { value: { label: null, cost: null, key: null }, label: null },
        additional_weeks: { value: { weeks: null, cost: null }, label: null },
        payment_type: { value: { label: null, discount: null }, label: null },
        payment_plan: [],
      }
    case "duration":
      return {
        ...state,
        duration: action.payload,
        level: { value: { label: null, cost: null, key: null }, label: null },
        additional_weeks: { value: { weeks: null, cost: null }, label: null },
        payment_type: { value: { label: null, discount: null }, label: null },
        payment_plan: [],
      }
    case "level":
      return {
        ...state,
        level: action.payload,
        additional_weeks: { value: { weeks: null, cost: null }, label: null },
        payment_type: { value: { label: null, discount: null }, label: null },
        payment_plan: [],
      }
    case "additional_weeks":
      return {
        ...state,
        additional_weeks: action.payload,
        payment_type: { value: { label: null, discount: null }, label: null },
        payment_plan: [],
      }
    case "payment_type":
      return {
        ...state,
        payment_type: action.payload,
        payment_plan: [],
      }
    case "payment_plan":
      return { ...state, payment_plan: action.payload }
    default:
      return state
  }
}

export default Reducer
