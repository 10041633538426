import React, { useState, useRef, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  Container,
  Label,
  HtmlSelect,
  SelectCustomWrapper,
  CustomSelect,
  CustomSelectTrigger,
  CustomOptions,
  OptionContainer,
  CustomOption,
  Arrow,
} from "./SelectStyles"
import { useOnClickOutside } from "@hooks"

const Select = ({
  options,
  selectProps = null,
  value,
  error = false,
  name,
  placeholder = "Select",
  onSelect = null,
  disabled = false,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState(value)
  const selectRef = useRef(null)

  useOnClickOutside(selectRef, () => {
    setOpen(false)
  })

  useEffect(() => {
    setSelected(value)
  }, [value])

  if (options) {
    return (
      <Container>
        <Label htmlFor={name}>{placeholder}</Label>
        <HtmlSelect {...selectProps} disabled={disabled} id={name} name={name}>
          {options.map(item => (
            <option key={uuidv4()} value={item.value}>
              {item.label}
            </option>
          ))}
        </HtmlSelect>
        <SelectCustomWrapper
          aria-hidden="true"
          ref={selectRef}
          onClick={() => {
            if (!disabled) {
              setOpen(prev => !prev)
            }
          }}
        >
          <CustomSelect error={error}>
            <CustomSelectTrigger>
              <span>
                {selected ? selected.label : error ? error : placeholder}
              </span>
              <Arrow isOpen={isOpen} />
            </CustomSelectTrigger>
            <CustomOptions isOpen={isOpen}>
              {options.map(item => (
                <OptionContainer
                  aria-hidden="true"
                  key={uuidv4()}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(item)
                    } else {
                      setSelected(item)
                    }
                  }}
                >
                  <CustomOption
                    isSelected={selected && selected.value === item.value}
                    data-value={item.value}
                  >
                    {item.label}
                  </CustomOption>
                </OptionContainer>
              ))}
            </CustomOptions>
          </CustomSelect>
        </SelectCustomWrapper>
      </Container>
    )
  }
  return null
}

export default Select
