import React, { useEffect } from "react"
import { createPortal } from "react-dom"

import { ModalWrapper, ModalBody, CloseButton } from "./ModalStyles"
import { useKeyPress } from "../../hooks"

const Portal = ({ children }) => {
  const modalRoot = document.getElementById("modal-root")
  const element = document.createElement("div")

  useEffect(() => {
    let mounted = true
    if (mounted) {
      modalRoot.appendChild(element)
    }
    return () => {
      mounted = false
      modalRoot.removeChild(element)
    }
  }, [modalRoot, element])

  return createPortal(children, element)
}

const Modal = ({ children, toggle, open }) => {
  const escPress = useKeyPress("Escape")

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (escPress && open) {
        toggle()
      }
    }
    return () => {
      mounted = false
    }
  }, [escPress, open, toggle])
  return (
    <Portal>
      {open && (
        <ModalWrapper onClick={toggle}>
          <ModalBody onClick={e => e.stopPropagation()}>
            <CloseButton onClick={toggle}>&times;</CloseButton>
            {children}
          </ModalBody>
        </ModalWrapper>
      )}
    </Portal>
  )
}

export default Modal
