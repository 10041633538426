import Styled from "styled-components"

const ModalWrapper = Styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBody = Styled.div`
  background-color: ${({ theme }) => theme.black};
  border: ${({ theme }) => `1px solid ${theme.primary}`};
  margin: auto;
  padding: 20px;
  width: 90%;
`

const CloseButton = Styled.span`
  color: ${({ theme }) => theme.white};
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    cursor: pointer;
  }
`

export { ModalWrapper, ModalBody, CloseButton }
