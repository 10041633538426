import Styled, { css } from "styled-components"

export const Container = Styled.div`
    width: 100%;
    min-width: 368px;
    margin-top: 20px;
`
export const Label = Styled.label`
  user-select: none;
  font-family: ${({ theme }) => theme.robotoFont};
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
`
export const HtmlSelect = Styled.select`
  display: none;
`
export const SelectCustomWrapper = Styled.div`
  position: relative;
  user-select: none;
  width: 100%;
`
export const CustomSelect = Styled.div`
  padding: 16px 16px 16px 16px;
  width: 100%;
  color: ${({ theme }) => theme.primary};
  ${({ theme, error }) => {
    if (error)
      return css`
        border: solid ${theme.error} 1px;
      `
    else
      return css`
        border: solid #484848 1px;
        &:focus {
          border: solid ${({ theme }) => theme.primary} 1px;
        }
      `
  }}
  outline: none;
  padding-right: 8px;
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.black};
  position: relative;
  cursor: pointer;
`
export const CustomSelectTrigger = Styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
const OptionsOpen = css`
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  margin-top: 8px;
  box-shadow: -1px 1px 2px rgba(67, 70, 74, 0.0001),
    -2px 2px 5px rgba(67, 86, 100, 0.123689);
`
export const CustomOptions = Styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.black};
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  padding: 8px;
  max-height: 200px;
  overflow-y: auto;
  ${({ isOpen }) => (isOpen ? OptionsOpen : null)}
`
const OptionSelected = css`
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.white};
`
export const CustomOption = Styled.span`
  position: relative;
  display: block;
  padding: 5px 8px;
  cursor: pointer;
  transition: all 0.5s;
  color: ${({ theme }) => theme.primary};
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  ${({ isSelected }) => (isSelected ? OptionSelected : null)}
`
export const OptionContainer = Styled.div`
  border: ${({ theme }) => `1px solid ${theme.lightGrey}`};
  &:hover ${CustomOption} {  
    cursor: pointer;
    background-color: ${({ theme }) => theme.lightGrey};
  }
`

const OpenArrow = css`
  &::before {
    left: -2px;
    transform: rotate(45deg);
  }
  &::after {
    left: 2px;
    transform: rotate(-45deg);
  }
`
export const Arrow = Styled.div`
  position: relative;
  height: 10px;
  width: 10px;
  &::before, &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.25rem;
    height: 100%;
    transition: all 0.5s;
  }
  &::before {
    background-color: ${({ theme }) => theme.primary};
    left: -2px;
    transform: rotate(-45deg);
  }
  &::after {
    background-color: ${({ theme }) => theme.primary};
    left: 2px;
    transform: rotate(45deg);
  }
  ${({ isOpen }) => (isOpen ? OpenArrow : null)}
`
