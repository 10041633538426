import React, { useState } from "react"

import Modal from "@components/Modal"
import { Seperator, Container, H6, H3 } from "./SharedStyles"

const LeanMass = () => {
  const [open, setOpen] = useState(false)
  return (
    <Seperator>
      <H6 onClick={() => setOpen(!open)}>Lean Mass</H6>
      <Modal toggle={() => setOpen(!open)} open={open}>
        <Container>
          <h2>Lean Mass</h2>
          <h3>Who’s this for?</h3>
          <h4>
            Those who want to build lean muscle and athletes in their off
            season.
          </h4>
          <section>
            <H3 level={"bronze"}>Bronze:</H3>
            <ul>
              <li>1x Email check in</li>
              <li>1x What’s App Check in’s per week</li>
            </ul>
          </section>
          <section>
            <H3 level={"silver"}>Silver:</H3>
            <ul>
              <li>2x Email check in’s</li>
              <li>2x What’s App Check in’s per week</li>
            </ul>
          </section>
          <section>
            <H3 level={"gold"}>Gold:</H3>
            <ul>
              <li>3x Email check in’s</li>
              <li>3x What’s App Check in’s per week.</li>
            </ul>
          </section>
          {/* <section>
            <H3 level={"platinum"}>Platinum:</H3>
            <ul>
              <li>3x Email check in’s</li>
              <li>4x What’s App Check in’s per week</li>
              <li>Plus 1x 15Mins Skype call each week</li>
            </ul>
          </section> */}
        </Container>
      </Modal>
    </Seperator>
  )
}

export default LeanMass
