import Styled, { css } from "styled-components"

import { GenerateClamp } from "@utils"

const StaticContainer = Styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  /* medium break point :: col-md-8 */
  @media screen and (min-width: 768px) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
`
const InnerStatic = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const SeperatorHover = css`
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.white};
    h6 {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const Seperator = Styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;  
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary};
  ${({ isTitle }) => (isTitle ? null : SeperatorHover)};
`
const Container = Styled.div`
  h2, h3, h4, ul, li {
    font-family: ${({ theme }) => theme.robotoFont};
  }
  h2 {
    text-align: center;
    color: ${({ theme }) => theme.primary};
  }
  h3 {
    text-align: center;
  }
  h4 {
    text-align: center;
    color: ${({ theme }) => theme.primary};
  }
  ul {
      li {
          &::marker {
              color: ${({ theme }) => theme.primary};
          }
      }
    }
`
const H3 = Styled.h3`
  text-align: left !important;
  color: ${({ theme, level }) => theme[level]};
`
const H6 = Styled.h6`
  text-transform: uppercase;
  font-size: ${GenerateClamp(360, 1200, 0.65, 1.5)};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.robotoFont};
  cursor: pointer;
  margin: 0;
  transition: all 0.2s linear;
`
const H5 = Styled.h5`
  text-transform: uppercase;
  font-size: ${GenerateClamp(360, 1200, 1.0, 1.5)};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.robotoFont};
  cursor: pointer;
  margin: 0;
  transition: all 0.2s linear;
`

export { StaticContainer, InnerStatic, Seperator, Container, H6, H5, H3 }
