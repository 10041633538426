import React from "react"

import { LeanMass, Transformation, PreContest } from "@components/ProgramLevels"
import { Seperator, H5, StaticContainer, InnerStatic } from "./SharedStyles"

const Title = () => (
  <Seperator isTitle>
    <H5>Package Level Details</H5>
  </Seperator>
)

const StaticPlacement = () => (
  <StaticContainer>
    <Title>Package Information</Title>
    <InnerStatic>
      <LeanMass />
      <Transformation />
      <PreContest />
    </InnerStatic>
  </StaticContainer>
)

export default StaticPlacement
